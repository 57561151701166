<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'description'"
        :itemService="itemService"
        :itemFormRef="itemFormRef"
        :modalSize="'modal-xl'"
        :selectFields=selectFields
    >
        <template #columns>
            <Column field="description" :header="$t('form.description')"></Column>
            <Column field="purchase_rate" :header="$t('sales.purchase_rates')" style="width: 15%">
                <template #body="slotProps">
                  <template v-if='slotProps.data.commodity_dependent'> - </template>
                  <template v-else-if='slotProps.data.managed_by_relations'> - </template>
                  <template v-else> {{slotProps.data.purchase_rate_currency.code}} {{slotProps.data.purchase_rate}} </template>
                </template>
            </Column>
            <Column field="sales_rate" :header="$t('sales.sales_rates')" style="width: 15%">
                <template #body="slotProps">
                  <template v-if='slotProps.data.commodity_dependent'> - </template>
                  <template v-else-if='slotProps.data.managed_by_relations'> - </template>
                  <template v-else> {{slotProps.data.sales_rate_currency.code}} {{slotProps.data.sales_rate}} </template>
                </template>
            </Column>
            <Column field="managed_by_relations" :header="$t('sales.per_relation')" style="width: 15%">
                <template #body="slotProps">
                  <template v-if='slotProps.data.managed_by_relations'>
                      {{slotProps.data.relationtype.name}}
                  </template>
                </template>
            </Column>
            <Column field="commodity_dependent" :header="$t('sales.per_commodity')" style="width: 15%">
                <template #body="slotProps">
                  <i v-if='slotProps.data.commodity_dependent' class='fas fa-check text-success' />
                </template>
            </Column>
        </template>

        <template #form>
            <GeneralcostForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import GeneralcostForm from '@/components/generalcost/GeneralcostForm.vue';
import generalcostService from '@/services/GeneralcostService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        GeneralcostForm
    },
    data() {
        return {
            item: {},
            itemService: generalcostService,
            itemFormRef: null,
            selectFields: [
                "id",
                "description",
                "managed_by_relations",
                "commodity_dependent",
                "purchase_rate",
                "sales_rate",
                'sales_rate_currency_id',
                'purchase_rate_currency_id',
                'relation_type_id',
                {
                    "relationtype": [
                        "id",
                        "name"
                    ]
                },
                {
                    "salesRateCurrency": [
                        "id",
                        "code"
                    ]
                },
                {
                    "purchaseRateCurrency": [
                        "id",
                        "code"
                    ]
                }
            ]
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>