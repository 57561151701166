<template v-if="formActive">
    <FormItem id="description" :label="$t('form.description')" v-model="generalcost.description" :required="true" />
    <FormItem id="managed_by_relations" :label="$t('masterdata.relation')" v-model="generalcost.managed_by_relations" type="checkbox" @onclick="(e) => checkboxEvent(e, 1)" />
    <FormItem v-if="generalcost.managed_by_relations" id="relation_id" :label="$t('navigation.relations')" v-model="generalcost.relation_type_id" type="select" :options="relationTypes" :required="true" @onchange="(e) => askForConfirm(e, 1)" />
    <FormItem id="commodity_dependent" :label="$t('masterdata.commodity')" v-model="generalcost.commodity_dependent" type="checkbox" @onclick="(e) => checkboxEvent(e, 2)" />

    <!-- Simple -->
    <template v-if="!generalcost.managed_by_relations && !generalcost.commodity_dependent">
        <FormItem id="purchase" type="currency-and-unit" :label="$t('sales.purchase_rates')" v-model="generalcost" :options="currencyAndUnitOptionsSpecial" :required="true" inputGroupType="purchase_rate" sizeItem="4" />
        <FormItem id="sales" type="currency-and-unit" :label="$t('sales.sales_rates')" v-model="generalcost" :options="currencyAndUnitOptionsSpecial" :required="true" inputGroupType="sales_rate" sizeItem="4" />
    </template>

    <!-- Per commodity -->
    <template v-else-if="!generalcost.managed_by_relations && generalcost.commodity_dependent">
        <div class="row mt-1">
            <div class="col-sm-4"></div>
            <div class="col-sm-4 col-form-label">{{$t('sales.purchase_rates')}}</div>
            <div class="col-sm-4 col-form-label">{{$t('sales.sales_rates')}}</div>
        </div>
        <div class="row mt-2" v-for="comm, index in generalcost.commodities" :key="comm.id">
            <div class="col-sm-4 text-end">
                <label class="col-form-label col-form-label-sm">{{comm.name}}</label>
            </div>
            <div class="col-sm-4">
                <FormItem id="purchase" type="currency-and-unit" v-model="generalcost.commodities[index]" :options="currencyAndUnitOptions" :required="true" inputGroupType="purchase_rate" sizeItem="4" />
            </div>
            <div class="col-sm-4">
                <FormItem id="sales" type="currency-and-unit" v-model="generalcost.commodities[index]" :options="currencyAndUnitOptions" :required="true" inputGroupType="sales_rate" sizeItem="4" />
            </div>
        </div>
    </template>
                            
</template>

<script>
    import currencyservice from '@/services/CurrencyService'
    import calculationunitservice from '@/services/CalculationunitService'
    import commodityservice from '@/services/CommodityService'
    import dataStore from '@/store/data'
    
    export default {
        name: 'GeneralcostForm',
        props: ['itemData'],
        data() {
            return {
                generalcost: {},
                commodities: [],
                currencies: [],
                calculation_units: [],
                relationTypes: [],
                submitted: false,
                loading: false,
                invalid: {},
                isEditing: false,
                hasEditedRelation: false,
                hasEditedCommodity: false,
                commodityEditBeforeEdit: null,
                originalRelation: null,
                currencyAndUnitOptions: { 'currencies': [], 'calculation_units': []},
                currencyAndUnitOptionsSpecial: { 'currencies': [], 'calculation_units': []},
                dataItemsLoaded: 0,
                dataItemsToLoad: 3,
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.generalcost = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let message = "There are fields yet to be filled in.";
                let valid = true;

                //Check selects and number fields
                let required = document.querySelectorAll('#formModal input[type="text"], #formModal input[type="number"], #formModal select');
                for(let field of required) {
                    if(!field.value && !field.classList.contains('not-required')){
                        valid = false;
                        field.classList.add("is-invalid");
                        message = this.$t('errors.empty_fields');
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }
                //Specifically for commodity-dependent fields
                required = document.querySelectorAll('#formModal .calc-unit');
                let size = required.length;
                for(let i = 0; i < size; i += 2) {
                    let field = required[i];
                    if(!field.value && this.generalcost.commodities[i / 2].purchase_rate){
                        valid = false;
                        field.classList.add("is-invalid");
                        message = this.$t('errors.empty_fields');
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                    field = required[i + 1];
                    if(!field.value && this.generalcost.commodities[i / 2].sales_rate){
                        valid = false;
                        field.classList.add("is-invalid");
                        message = this.$t('errors.empty_fields');
                    }
                    else{
                        field.classList.remove("is-invalid");
                    }
                }

                if(!valid) alert(message);
                if(valid && this.commodityEditBeforeEdit && !this.generalcost.commodity_dependent){
                    return confirm(this.$t('errors.warning_gen_costs'));
                }
                if(valid && this.generalcost.managed_by_relations){
                    this.generalcost.sales_rate = '';
                    this.generalcost.sales_rate_currency_id = window.defaultCurrency;
                    this.generalcost.purchase_rate = '';
                    this.generalcost.purchase_rate_currency_id = window.defaultCurrency;
                    this.generalcost.sales_rate_calculation_unit_id = 1;
                    this.generalcost.purchase_rate_calculation_unit_id = 1;
                    if(this.generalcost.commodity_dependent){
                        this.generalcost.commodities.forEach(comm => {
                            comm.sales_rate = '0';
                            comm.purchase_rate = '0';
                            comm.sales_rate_currency_id = window.defaultCurrency;
                            comm.purchase_rate_currency_id = window.defaultCurrency;
                            comm.purchase_rate_calculation_unit_id = 1;
                            comm.sales_rate_calculation_unit_id = 1;
                        });
                    }
                }
                return valid;
            },
            validateField(e){
                let value = e.target.value;
                let valid = value;
                if(e.target.nodeName == "SELECT"){
                    e.target.classList.remove('is-invalid');
                }
                else if(valid){
                    e.target.classList.add('is-valid');
                    e.target.classList.remove('is-invalid');
                }
            },
            getData() {
                return this.generalcost;
            },
            checkboxEvent(e, value){
                this.askForConfirm(e, value, true);
            },
            askForConfirm(event, type, setComms = false){
                if(this.isEditing){
                    let msg = null;
                    if((type == 1 && !this.hasEditedRelation) || (type == 2 && !this.hasEditedCommodity && !this.commodityEditBeforeEdit)) {
                        msg = this.$t('errors.warning_gen_costs');
                    }
                    if(msg != null){
                        if(confirm(msg)){
                            if(type == 1) this.hasEditedRelation = true;
                            else this.hasEditedCommodity = true;
                        }
                        else{
                            event.preventDefault();
                            if(event.target.tagName === 'SELECT'){
                                this.$nextTick(() => {
                                    this.generalcost.relation_type_id = this.originalRelation;
                                    this.$forceUpdate();
                                });
                            }
                            return false;
                        }
                    }
                }
                if(setComms){
                    this.setCommodities(type, event.target.checked)
                }
            },
            setData(data) {
                if(data) { 
                    this.generalcost = data;
                    this.originalRelation = (data.relation_type_id);
                    for(let comm of this.generalcost.commodities){
                        if(comm.commodity) comm.name = this.compactName(comm.commodity.name);
                    }
                }
                if(this.dataItemsLoaded < this.dataItemsToLoad) return;

                if(!this.generalcost.commodities || this.generalcost.commodities.length == 0){
                    this.generalcost.commodities = this.commodities;
                }
                this.currencyAndUnitOptions = { 'currencies': this.currencies, 'calculation_units': this.calculation_units.filter(unit => unit.id < 5)};
                this.currencyAndUnitOptionsSpecial = { 'currencies': this.currencies, 'calculation_units': this.calculation_units};
                this.isEditing = true;
                this.commodityEditBeforeEdit = this.generalcost.commodity_dependent ? true : false;
                this.hasEditedRelation = false;
                this.dataItemsLoaded = 0;
            },
            reset(isEditing = false) {
                this.isEditing = isEditing;
                if(this.dataItemsLoaded < this.dataItemsToLoad){
                    this.loadData();
                    return;
                }

                this.generalcost = {
                    'description': '',
                    'sales_rate': '',
                    'sales_rate_currency_id': window.defaultCurrency,
                    'purchase_rate': '',
                    'purchase_rate_currency_id': window.defaultCurrency,
                    'sales_rate_calculation_unit_id': '',
                    'purchase_rate_calculation_unit_id': '',
                    'managed_by_relations': false,
                    'commodity_dependent': false,
                    'relation_type_id': null,
                    'commodities': []
                }
                this.loading = false
                this.invalid = {}
                this.isEditing = false
                let required = document.querySelectorAll('#formModal input[required], #formModal select[required]');
                for(let field of required) {
                    field.classList.remove("is-valid");
                    field.classList.remove("is-invalid");
                }
                this.currencyAndUnitOptions = { 'currencies': this.currencies, 'calculation_units': this.calculation_units.filter(unit => unit.surcharge_only == false)};
                this.currencyAndUnitOptionsSpecial = { 'currencies': this.currencies, 'calculation_units': this.calculation_units};
                this.dataItemsLoaded = 0;
            },
            getCommodities() {
                this.commodities = [];
                commodityservice.index().then(response => {
                    response.data.forEach(comm => {
                        this.commodities.push({
                            commodity_id: comm.id,
                            name: this.compactName(comm.name),
                            sales_rate: '',
                            sales_rate_currency_id: window.defaultCurrency,
                            sales_rate_calculation_unit_id: '',
                            purchase_rate: '',
                            purchase_rate_currency_id: window.defaultCurrency,
                            purchase_rate_calculation_unit_id: ''
                        });
                    });
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => {
                    console.log('error', error)
                })
            },
            setCommodities(type, value){
                if((this.generalcost.commodity_dependent) || (type == 2 && value)){
                    this.generalcost.commodities = this.commodities;
                }
                if(type == 2 && !value){
                    this.generalcost.commodities = [];
                }
            },
            getCurrencies() {
                currencyservice.index().then(response => {
                    this.currencies = response.data
                    window.defaultCurrency = this.currencies.filter(currency => currency.is_default == 1)[0].id;
                    this.dataItemsLoaded++;
                    this.getCommodities()
                }).catch(error => {
                    console.log('error', error)
                })
            },
            getRelationTypes(){
                this.relationTypes = dataStore.getters.getRelationTypes;
            },
            getCalculationunits() {
                calculationunitservice.index().then(response => {
                    this.calculation_units = response.data.filter(cu => !cu.surcharge_only);
                    this.dataItemsLoaded++;
                    this.checkData();
                }).catch(error => {
                    console.log('error', error)
                })
            },
            compactName(name){
                let newName = name;
                return (newName.length > 32) ? (newName.substring(0, 30) + '...') : newName;
            },
            getTName(type){
                let newName = type.name;
                newName = newName.replace(/[A-Z]/g, (c) => {return '_' + c.toLowerCase()});
                if(newName[newName.length - 1] == 's')
                    return 'form.' + newName;
                return 'navigation.' + newName + 's';
            },
            checkData(){
                if(this.dataItemsLoaded >= this.dataItemsToLoad){
                    if(this.isEditing){
                        this.setData(false);
                    }
                    else{
                        this.reset();
                    }
                }
            },
            loadData(){
                this.getCurrencies()
                this.getCalculationunits()
                this.getRelationTypes()
            }
        }
    }
</script>